@font-face {
  font-family: 'ITC Avant Garde';
  src: url('../font/ITCAvantGardeStd_Bold.otf') format('opentype');
}

* {
  font-family: 'ITC Avant Garde', sans-serif;
}

body {
  background-color: rgba(185, 182, 182, 0.315);
  margin: 0;
}

.header {
  background-color: #fff;
  padding: 15px;
}

.footer {
  position: fixed;
  bottom: 0;
  /* margin: auto; */
  color: #fff;
  text-align: left;
  height: 18px;
  width: 100%;
  padding-top: 5px;
  padding-left: 2px;
  font-size: 11px;
  white-space: nowrap;
  text-shadow: 0px 0px 6px black;
}

.footer a {
  color: #23b4ed;
}

.logo {
  text-align: left;
}

.logo img {
  width: 280px;
}

.header-text {
  text-align: center;
  background-image: url(../img/storiboxHeader.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 120px;
  width: 100%;
  margin-bottom: 50px;
}



.header-logo {
  text-align: center;
  background-image: url(../img/storiboxLogo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 218px;
  margin-bottom: 30px;
}

.card-contain {
  text-align: center;
  width: 470px;
  margin: auto;
}

input {
  padding: 10px;
  border: 1px solid #848484;
  border-right: none;
  width: 100%;
  background: #f1f1f1;
  box-sizing: border-box;
  height: 40px;
  border-radius: 10px 10px 10px 10px;
  font-size: 18px;
  padding-right: 45px;
}

button {
  height: 55px;
  width: 200px;
  border-radius: 35px;
  background: -webkit-linear-gradient(#FF5252, #FF4081); /* For Safari 5.1 to 6.0*/
  background: -o-linear-gradient(#FF5252, #FF4081); /* For Opera 11.1 to 12.0*/
  background: -moz-linear-gradient(#FF5252, #FF4081); /* For Firefox 3.6 to 15*/
  background: linear-gradient(#FF5252, #FF4081); /*Standard syntax*/
  border: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  line-height: 60px;
}

.scan-card {
  padding: 10px;
  border: 1px solid #848484;
  border-right: none;
  float: left;
  width: 370px;
  background: #f1f1f1;
  box-sizing: border-box;
  height: 55px;
  border-radius: 10px 10px 10px 10px;
  font-size: 18px;
  margin-right: 15px;
}
.email-act{
  width: 200px !important;
  float: none !important;
}
.scan-card-act {
  float: left;
  height: 55px;
  width: 85px;
  border-radius: 35px;
  background-image: url('../img/go.png'), linear-gradient(#FF5252, #FF4081);
  background-size: 68px 23px, cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  border: none; /* Remove any default button styles */
  cursor: pointer;

}


*:focus {
  outline: none;
}

.help {
  width: 320px;
  height: 55px;
  cursor: pointer;
  font-size: 18px;
  color: #f5e9e9;
  padding-top: 50px;
  text-decoration: underline;
}

.card-scan {
  height: 60px;
}

.att-email {
  display: none;
  height: 160px;
}

.msg {
  color: #fff;
  height: 50px;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensures video stays in the background */
}
.background-video {
  background: url('../img/cities.jpg') no-repeat center center;
  background-size: cover;
}

/* Mobile Background Video */
@media (max-width: 768px) {
  .background-video source {
    content: url("../videos/citiesMobile.mp4"); /* Use mobile video */
  }
  .background-video {
    background: url('../img/citiesMobile.jpg') no-repeat center center;
    background-size: cover;
  }
  .header-logo {
    height: 150px;
  }
  .header-text {
    width: 85%;
    margin: 0 auto;
  }
  .scan-card {
    height: 45px;
    width: 215px;
  }
  .scan-card-act {
    height: 45px;
    align-items: center; /* Ensures vertical centering */
    justify-content: center;
    padding: 0; /* Ensures no extra padding */
    line-height: 40px; /* Match height for consistent centering */
    border: none; /* Remove any default button styles */
  }
  button {
    height: 40px;
    line-height: 45px;
  }
  .card-contain{
    width: 315px;
  }
}

