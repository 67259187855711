@font-face {
  font-family: ITC Avant Garde;
  src: url("ITCAvantGardeStd_Bold.002e42a5.otf") format("opentype");
}

* {
  font-family: ITC Avant Garde, sans-serif;
}

body {
  background-color: #b9b6b650;
  margin: 0;
}

.header {
  background-color: #fff;
  padding: 15px;
}

.footer {
  color: #fff;
  text-align: left;
  height: 18px;
  width: 100%;
  white-space: nowrap;
  text-shadow: 0 0 6px #000;
  padding-top: 5px;
  padding-left: 2px;
  font-size: 11px;
  position: fixed;
  bottom: 0;
}

.footer a {
  color: #23b4ed;
}

.logo {
  text-align: left;
}

.logo img {
  width: 280px;
}

.header-text {
  text-align: center;
  height: 120px;
  width: 100%;
  background-image: url("storiboxHeader.9e94367e.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 50px;
}

.header-logo {
  text-align: center;
  height: 218px;
  background-image: url("storiboxLogo.7883d963.png");
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 30px;
}

.card-contain {
  text-align: center;
  width: 470px;
  margin: auto;
}

input {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  background: #f1f1f1;
  border: 1px solid #848484;
  border-right: none;
  border-radius: 10px;
  padding: 10px 45px 10px 10px;
  font-size: 18px;
}

button {
  height: 55px;
  width: 200px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(#ff5252, #ff4081);
  border: none;
  border-radius: 35px;
  font-size: 25px;
  line-height: 60px;
}

.scan-card {
  float: left;
  width: 370px;
  box-sizing: border-box;
  height: 55px;
  background: #f1f1f1;
  border: 1px solid #848484;
  border-right: none;
  border-radius: 10px;
  margin-right: 15px;
  padding: 10px;
  font-size: 18px;
}

.email-act {
  width: 200px !important;
  float: none !important;
}

.scan-card-act {
  float: left;
  height: 55px;
  width: 85px;
  color: #fff;
  cursor: pointer;
  cursor: pointer;
  background-image: url("go.368e1142.png"), linear-gradient(#ff5252, #ff4081);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 68px 23px, cover;
  border: none;
  border-radius: 35px;
  font-size: 25px;
}

:focus {
  outline: none;
}

.help {
  width: 320px;
  height: 55px;
  cursor: pointer;
  color: #f5e9e9;
  padding-top: 50px;
  font-size: 18px;
  text-decoration: underline;
}

.card-scan {
  height: 60px;
}

.att-email {
  height: 160px;
  display: none;
}

.msg {
  color: #fff;
  height: 50px;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  background: url("cities.3013f36c.jpg") center / cover no-repeat;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .background-video source {
    content: url("citiesMobile.d9fdbd35.mp4");
  }

  .background-video {
    background: url("citiesMobile.7f55cddb.jpg") center / cover no-repeat;
  }

  .header-logo {
    height: 150px;
  }

  .header-text {
    width: 85%;
    margin: 0 auto;
  }

  .scan-card {
    height: 45px;
    width: 215px;
  }

  .scan-card-act {
    height: 45px;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    line-height: 40px;
  }

  button {
    height: 40px;
    line-height: 45px;
  }

  .card-contain {
    width: 315px;
  }
}

/*# sourceMappingURL=index.c7a43b3c.css.map */
